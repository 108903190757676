"use client"
import Image from "next/image";
import Link from "next/link";
import DarkMode from "../../utils/DarkMode";
import Logo from "../../../public/images/logo.png"
import WhiteLogo from "../../../public/images/logo_white.png";
import { v4 as uuidv4 } from "uuid";
import { useRouter, usePathname } from "next/navigation";
import {
  isChildrenPageActive,
  isParentPageActive,
} from "../../utils/DynamicNavigation";
import { useEffect, useState } from "react";
import { ProfileButton } from "./ProfileButton";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { websiteName } from "@/constants/BasicData";

export default function Header() {
  const [toggle, setToggle] = useState(false);
  const [isCollapse, setCollapse] = useState(0);
  const { profile, shop } = useSelector(({ auth }: RootState) => auth)

  // window resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        setToggle(false);
      }
    });
  });

  const pathname = usePathname();
  /* -------------------------------------------------------------------------- */
  /*                            daynamic navigations                            */
  /* -------------------------------------------------------------------------- */

  const resources = {
    id: 4,
    name: "Resources",
    hasChildren: true,
    url: '',
    pages: [
      {
        id: uuidv4(),
        name: "Getting Started",
        path: "/#getstarted",
      },
      {
        id: uuidv4(),
        name: "Compensation Plan",
        path: "https://drive.google.com/file/d/1fzv0wKuXwFO6rbIlu3haU7Q94ZY7k2fL/view?usp=sharing",
      },
      {
        id: uuidv4(),
        name: "Terms Of Service",
        path: "/tos",
      },
      {
        id: uuidv4(),
        name: "Faqs",
        path: "/faqs",
      },
    ],
  };

  const menus = [
    { id: 1, name: "Home", url: "/", hasChildren: false, pages: [] },
    { id: 1, name: "About", url: "/about", hasChildren: false, pages: [] },
    { id: 1, name: "Services", url: "/services", hasChildren: false, pages: [] },
    { id: 1, name: "Shops", url: "/shops", hasChildren: false, pages: [] },
    { id: 1, name: "Products", url: "/products", hasChildren: false, pages: [] },
    resources,
    { id: 1, name: "Login", url: "/login", hasChildren: false, pages: [] },
  ]

  const mobileCollapse = (id: number) => {
    if (isCollapse === id) {
      return setCollapse(0);
    }
    setCollapse(id);
  };

  return (
    <>
      {/* main desktop menu sart*/}
      <header className="js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors">
        <div className="flex items-center px-6 py-6 xl:px-24 ">
          <Link className="shrink-0" href="/">
            <span>
              <div className="dark:hidden">
                {/* height={28}
                  width={130} */}
                <img
                  src="/images/logo.png"
                  className="max-h-7 h-auto "
                  alt={websiteName}
                />
              </div>
              <div className="hidden dark:block">
                <img
                  src="/images/logo_white.png"
                  className="max-h-20 h-auto "
                  alt={websiteName}
                />
              </div>
            </span>
          </Link>
          {/* End  logo */}
          {/* <span className=" ml-auto relative items-center">10 total shops</span> */}
          {
            pathname != '/' ? <form
              action="products"
              className="relative ml-12 mr-8 hidden basis-3/12 lg:block xl:ml-[8%]"
            >
              <input
                name="search"
                type="search"
                className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-[0.6875rem] px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
                placeholder="Search"
              />
              <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="fill-jacarta-500 h-4 w-4 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
                </svg>
              </span>
            </form> : <Link href='/'>
            </Link>
          }
          {/* End Desktop search form */}

          <div className="js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent">
            <nav className="navbar w-full">
              <ul className="flex flex-col lg:flex-row">

                {
                  menus.map((m, i) => {
                    return m.name == 'Login' && profile ? <span key={`md_${i}`}></span> : !m.hasChildren ? (
                      <li className="group" key={`md_${i}`}>
                        <Link href={m.url}>
                          <span>
                            <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                              <span
                                className={
                                  isChildrenPageActive(pathname, m.url)
                                    ? "text-accent dark:text-accent"
                                    : ""
                                }
                              >
                                {m.name}
                              </span>
                            </button>
                          </span>
                        </Link>
                      </li>
                    ) : (
                      <li className="js-nav-dropdown group relative" key={`md_${i}`}>
                        <button className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full">
                          <span
                            className={
                              isParentPageActive(m.pages, pathname)
                                ? "text-accent dark:text-accent"
                                : ""
                            }
                          >
                            {m.name}
                          </span>
                          <i className="lg:hidden">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width={24}
                              height={24}
                              className="h-4 w-4 dark:fill-white"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                            </svg>
                          </i>
                        </button>
                        <ul
                          className="dropdown-menu dark:bg-jacarta-800 left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative"
                          aria-labelledby="navDropdown-4"
                        >
                          {m?.pages?.map?.((page) => (
                            <li key={page.id}>
                              <Link href={page?.path}>
                                <span className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors">
                                  <span
                                    className={`font-display ${isChildrenPageActive(pathname, page.path)
                                      ? "text-accent dark:text-accent"
                                      : "text-jacarta-700"
                                      } text-sm dark:text-white`}
                                  >
                                    {page?.name}
                                  </span>
                                </span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    )
                  })
                }
              </ul>
            </nav>
            {/* End menu for desktop */}

            <div className="ml-8 hidden items-center lg:flex xl:ml-12">
              <ProfileButton profile={profile} shop={shop} />
              {/* <DarkMode /> */}
            </div>
            {/* End header right content (metamask and other) for desktop */}
          </div>
          {/* header menu conent end for desktop */}

          <div className="ml-auto flex lg:hidden">
            {/* <Link href="/profile/user_avatar">
              <span
                className="border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
                aria-label="profile"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                </svg>
              </span>
            </Link> */}
            <ProfileButton profile={profile} shop={shop} />
            {/* <DarkMode /> */}
            <button
              className="js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
              aria-label="open mobile menu"
              onClick={() => setToggle(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
              </svg>
            </button>
          </div>
          {/* End header right content  for mobile */}
        </div>
        {/* End flex item */}
      </header>
      {/* main desktop menu end */}

      {/* start mobile menu and it's other materials  */}
      <div
        className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${toggle ? "nav-menu--is-open" : "hidden"
          }`}
      >
        <div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
          <div className="dark:hidden">
            <img
              src="/images/logo.png"
              className="max-h-7 h-auto "
              alt={websiteName}
            />
          </div>

          <div className="hidden dark:block">
            <img
              src="/images/logo_white.png"
              className="max-h-7 h-auto "
              alt={websiteName}
            />
          </div>

          <button
            className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
            onClick={() => setToggle(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
            </svg>
          </button>
        </div>
        {/* mobile menu top header content */}

        <form action="products" className="relative mt-24 mb-8 w-full lg:hidden">
          <input
            name="search"
            type="search"
            className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-3 px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
            placeholder="Search"
          />
          <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-500 h-4 w-4 dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
            </svg>
          </span>
        </form>
        {/* End search form mobile menu  */}

        <nav className="navbar w-full">
          <ul className="flex flex-col lg:flex-row">

            {
              menus.map((m, i) => {
                return m.name == 'Login' && profile ? <span key={`mm_${i}`}></span> : !m.hasChildren ? (
                  <li className="group" onClick={() => setToggle(false)} key={`mm_${i}`}>
                    <Link href={m.url}>
                      <span>
                        <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                          <span
                            className={
                              isChildrenPageActive(pathname, m.url)
                                ? "text-accent dark:text-accent"
                                : ""
                            }
                          >
                            {m.name}
                          </span>
                        </button>
                      </span>
                    </Link>
                  </li>
                ) : (
                  <li className="js-nav-dropdown group relative" key={`mm_${i}`}>
                    <button
                      onClick={() => mobileCollapse(m.id)}
                      className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
                    >
                      <span
                        className={
                          isParentPageActive(m.pages, pathname)
                            ? "text-accent dark:text-accent"
                            : ""
                        }
                      >
                        {m.name}
                      </span>
                      <i className="lg:hidden">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width={24}
                          height={24}
                          className="h-4 w-4 dark:fill-white"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                        </svg>
                      </i>
                    </button>
                    <ul
                      className={`dropdown-menu left-0 top-[85%] z-10 grid-flow-row grid-cols-[repeat(2,_1fr)] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-jacarta-800 lg:invisible lg:absolute lg:!grid lg:translate-y-4 lg:py-8 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative ${isCollapse === m.id ? "block" : "hidden"
                        }`}
                      aria-labelledby="navDropdown-4"
                    >
                      {m?.pages?.map((page) => (
                        <li key={page.id} onClick={() => setToggle(false)}>
                          <Link href={page.path}>
                            <span className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors">
                              <span
                                className={`font-display text-jacarta-700 text-sm dark:text-white ${isChildrenPageActive(pathname, page.path)
                                  ? "text-accent dark:text-accent"
                                  : ""
                                  }`}
                              >
                                {page.name}
                              </span>
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                )
              })
            }


          </ul>
        </nav>
        {/* End navbar mobile menu  */}
      </div>
      {/* End mobile menu and it's other materials */}
    </>
  );
}
